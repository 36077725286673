.qmDialog .positionabs[data-v-3fb74ee0] {
  position: absolute;
  top: 10px;
  right: 50px;
}
.qmDialog .positionabs i[data-v-3fb74ee0] {
  cursor: pointer;
}
.qmDialog .el-dialog__footer[data-v-3fb74ee0] {
  border-top: 1px solid #e6e6e6;
  padding: 10px !important;
}
